import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

const logoImg = require("../dist/images/logo.png")
const headerPhoneImg = require("../dist/images/header-iphone-trouverty.png")
const feature1Img = require("../dist/images/feature-1.png")
const feature2Img = require("../dist/images/feature-2.png")
const agProfile = require("../dist/images/team/profile.jpg")


class IndexPage extends React.Component {

  render() {

    return (

      <div>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8"/>
            <title>Trouverty - Buy & Sell Property in Indonesia</title>
          </Helmet>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          
          <a className="navbar-brand logo-image" href="https://www.trouverty.com"><img src={logoImg} alt="alternative" /></a>
        
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-awesome fas fa-bars"></span>
            <span className="navbar-toggler-awesome fas fa-times"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link page-scroll active"  href="#header">Home</a> 
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#feature">Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#contact">Contact</a>
              </li>
            </ul>

            <span className="nav-item social-icons">
              <span className="fa-stack">
                <a href="https://www.facebook.com/trouverty/">
                  <i className="fas fa-circle fa-stack-2x facebook"></i>
                  <i className="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                    <a href="https://www.instagram.com/trouverty">
                        <i className="fas fa-circle fa-stack-2x instagram"></i>
                        <i className="fab fa-instagram fa-stack-1x"></i>
                    </a>
                </span>
            </span>
          </div>
        </nav>

        <header id="header" className="header">
          <div className="header-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-container">
                    <h1><span className="turquoise">Find your dream home here</span> 👇</h1>
                    <p className="p-large">Trouverty is a mobile-based application that designed to help Real-Estate Agents in selling their products as well as making it easier to communicate with their Clients.</p>
                    <a className="btn-solid-lg page-scroll" href="https://bit.ly/34zEuY8"><i class="fab fa-google-play"></i>DOWNLOAD NOW</a>
                    <a className="btn-outline-lg page-scroll" href="https://bit.ly/2raSIkK"><i class="fab fa-google-play"></i>DOWNLOAD AGENT</a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="image-container">
                    <img className="img-fluid" src={headerPhoneImg} alt="alternative"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="feature" className="cards-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Features</h2>
                <p className="p-heading-1 p-large">Trouverty was designed based on input from Real-Estate Agents so it offers all </p>
              </div>
            </div>
          </div>
        </div>

        <div className="basic-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="text-container">
                  <h2>Easy, Quick and Trusted</h2>
                  <p>Trouverty team is eager to offer you on the best feature that you should look into</p>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-search"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Find your dream property</div>
                        <p>With our search function you can easily find the property based on your criteria</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-comment-dots"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Chat</div>
                        <p>You can directly send message to the Agent for any further question</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Call</div>
                        <p>Trouverty provided a Call function for you to contact the Agent directly</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Agent Information</div>
                        <p>You can see detailed information about the Agent</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-container">
                  <img className="img-fluid" src={feature1Img} alt="alternative"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="basic-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="image-container">
                  <img className="img-fluid" src={feature2Img} alt="alternative"/>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="text-container">
                  <h2>Are you a Real-Estate Agent ?</h2>
                  <p>Trouverty team aslo provide a mobile application for Agents to sell their products and communicate with their Clients. Below are our best feature that you should look into</p>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-building"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Add/Edit/Remove Listing</div>
                        <p>You can manage to Add/Edit/Remove your listing</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-comment-dots"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Receive a Chat from Client</div>
                        <p>You can receive message directly from Client</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Receive a Call from Client</div>
                        <p>Client can call you directly anytime and anywhere</p>
                      </div>
                    </div>
                  </div>

                  <a className="btn-solid-lg page-scroll" href="https://bit.ly/2raSIkK"><i class="fab fa-google-play"></i>DOWNLOAD NOW</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="image-container">
                  <img className="img-fluid" src={headerPhoneImg} alt="alternative"/>
                </div>
              </div>

              <div className="col-lg-6">
                <h2>Why use Trouverty ?</h2>

                <div className="text-container">
                  <div className="card-feature left-pane first">
                    <div className="card-body">
                      <div className="card-icon">
                        <i className="fas fa-search"></i>
                      </div>
                      <div className="text-wrapper">
                        <div className="card-title">Find your criteria property easily</div>
                        <p>With Trouverty's search function, we always try to offer the best by combining the latest innovations and technology.</p>
                      </div>
                    </div>
                  </div>

                  <div className="card-feature left-pane first">
                      <div className="card-body">
                        <div className="card-icon">
                          <i className="fas fa-chart-bar"></i>
                        </div>
                        <div className="text-wrapper">
                          <div className="card-title">Market Analysis</div>
                          <p>It is our mission to help clients and agents, so we continue to conduct analysis from various data sources to improve the property search experience so that you can make smart decisions in buying and selling property.</p>
                        </div>
                      </div>
                  </div>

                  <div className="card-feature left-pane first">
                      <div className="card-body">
                        <div className="card-icon">
                          <i className="fas fa-mobile-alt"></i>
                        </div>
                        <div className="text-wrapper">
                          <div className="card-title">Chat & Call Function</div>
                          <p>We provided chat & call function for clients and agents, so they can communicate easily anytime and anywhere.</p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="about" className="basic-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>About</h2>
                <p className="p-heading p-large"></p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="team-member">
                  <div className="image-wrapper">
                    <img className="img-fluid" src={agProfile} alt="alternative"/>
                  </div>
                  <p className="p-large"><strong>Andi Goh</strong></p>
                  <p className="job-title">Founder / CEO</p>
                  <span className="social-icons">
                    <span className="fa-stack">
                      <a href="https://www.facebook.com/andigohzg">
                        <i className="fas fa-circle fa-stack-2x facebook"></i>
                        <i className="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                  <span className="social-icons">
                    <span className="fa-stack">
                      <a href="https://www.instagram.com/andigohh/">
                        <i className="fas fa-circle fa-stack-2x instagram"></i>
                        <i className="fab fa-instagram fa-stack-1x"></i>
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" className="form-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Contact</h2>
                <ul className="list-unstyled li-space-lg">
                  <li className="address">Don't hesitate to give us a call or send us a contact form message</li>
                  <li className="address"><i className="fas fa-map-marker-alt"></i>Batam, Indonesia</li>
                  <li><i className="fas fa-phone"></i><a className="turquoise" href="tel:07784086690">(0778) 408 6690</a></li>
                  <li><i className="fas fa-envelope"></i><a className="turquoise" href="mailto:cs@trouverty.com">cs@trouverty.com</a></li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form id="contactForm" data-toggle="" data-focus="false">
                  <div className="form-group">
                    <input type="text" className="form-control-input" id="cname" required/>
                    <label className="label-control" for="cname">Name</label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control-input" id="cemail" required/>
                    <label className="label-control" for="cemail">Email</label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control-textarea" id="cmessage" required/>
                    <label className="label-control" for="cname">Your Message</label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group checkbox">
                    <input type="checkbox" id="cterms" value="Agreed-to-Terms" required/>I have read and agree with Trouverty's stated <Link to="/">Privacy Policy</Link> and <Link to="/">Terms Conditions</Link>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="form-control-submit-button">SUBMIT</button>
                  </div>
                  <div className="form-message">
                    <div id="csgSubmit" className="h3 text-center hidden"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="p-small">Copyright © 2019 Trouverty</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IndexPage
